import { Login } from 'types/auth'
import fetchApi from './config'

export const login = async (params: Login) => {
  return fetchApi(process.env.REACT_APP_PORTAL_API + '/login', params, 'post')
}

export const logout = async () => {
  return fetchApi(process.env.REACT_APP_PORTAL_API + '/logout', '', 'post')
}
