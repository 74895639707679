import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Messages } from "../../types/common";
import { LayoutState } from "../../types/redux";

const initialState: LayoutState = {
  messages: {},
};

const layoutSlice = createSlice({
  initialState,
  name: "layout",
  reducers: {
    showMessage: (state, action) => {
      const { id, ...other } = action.payload;
      return { ...state, messages: { ...state.messages, [`${id}`]: other } };
    },
    hideMessage: (state, action) => {
      const newMessages = { ...state.messages };
      if (newMessages[action.payload]) {
        delete newMessages[action.payload];
      }
      return { ...state, messages: newMessages };
    },
  },
});

export const { actions: layoutActions, reducer: layoutReducer } = layoutSlice;
