// project import
import SimpleBar from 'components/third-party/SimpleBar'

import { Box, List } from '@mui/material'
import NavItem from './Navigation/NavItem'
import NavGroup from './Navigation/NavGroup'
import { useSelector } from 'react-redux'
// ==============================|| DRAWER CONTENT ||============================== //

import { MenuItem } from 'types/common'
import { RootState } from 'redux/store'
import menuItems from 'menu-items'
import './style.scss'

const DrawerContent = () => {
  const userInfo = useSelector((state: RootState) => state.user.user)

  const renderNavItem = (item: MenuItem) => {
    let level: number = userInfo.role?.level ? userInfo.role.level : -1;

    if (item.role?.includes('*') || item.role?.includes(level)) {
      if (item.children && Array.isArray(item.children)) {
        return <NavGroup key={`${item.id}`} item={item} />
      }

      return <NavItem item={item} key={item.id} />
    }

    return null
  }

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Box sx={{ flexGrow: 1, pt: 2 }}>
        <List className="menu-list">{(menuItems.items || []).map((item) => renderNavItem(item))}</List>
      </Box>
    </Box>
  )

  return (
    <SimpleBar
      sx={{
        '& .simplebar-content': {
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      {content}
      {/* <Navigation /> */}
    </SimpleBar>
  )
}

export default DrawerContent
