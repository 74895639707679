import ListIcon from '@mui/icons-material/List'

const store = {
  id: 'store',
  title: 'Kho vật lý',
  type: 'item',
  url: "stores",
  icon: ListIcon,
  breadcrumbs: false,
  role: ['*'],
}

export default store
