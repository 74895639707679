import { lazy } from 'react'

// project import
import Loadable from 'components/Loadable'

const OrderLising = Loadable(lazy(() => import('pages/Order/Listing')))
const OrderDetail = Loadable(lazy(() => import('pages/Order/Detail')))

const OrderRoutes = [
  {
    path: 'orders',
    element: <OrderLising />,
  },
  {
    path: 'orders/:id',
    element: <OrderDetail />,
  },
]

export default OrderRoutes