import { ListItemText } from '@mui/material'
import _, { isArray, isUndefined } from 'lodash'
import { v4 as uuid } from 'uuid'

import { ERROR_MESSAGE, HTTP_STATUS } from '../constants/common'
import { layoutActions } from '../redux/slices/layoutSlice'

export const uniqueId = () => uuid()

export const isSuccessRequest = (status) => {
  return [HTTP_STATUS.SUCCESS, HTTP_STATUS.CREATED_SUCCESS].includes(status)
}

export const createMessage = (msg) => {
  return layoutActions.showMessage({ ...msg, id: uuid(), createdAt: Date.now() })
}

export const parseURLQuery = (queryString = '') => {
  let strQuery = queryString?.trim()
  if (strQuery.indexOf('?') === 0) {
    strQuery = strQuery.substring(1)
  }

  let params = {}
  if (strQuery) {
    strQuery.split('&').forEach((query) => {
      const [key, value] = query.split('=')
      params[key] = decodeURI(value)
    })
  }

  return params
}

export const toBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

export const htmlDecode = (input) => {
  let doc = new DOMParser().parseFromString(input, 'text/html')
  return doc.documentElement.textContent
}

export const createMarkup = (str) => {
  return { __html: str }
}

export const detectMob = () => {
  const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i]

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem)
  })
}

/**
 * Biến đổi 1 object thành chuỗi Query string
 *
 * {name: 'abc', age: 10} => ?name=abc&age=10
 *
 * @param {object} data
 * @returns
 */
export const transformObjToUrlQuery = (data) => {
  if (data && _.isObject(data)) {
    let query = []

    Object.keys(data).forEach((key) => {
      if (!_.isUndefined(data[key]) && data[key] !== -1 && !_.isNull(data[key]) && data[key] !== '') {
        query.push(`${key}=${data[key]}`)
      }
    })

    return query.length > 0 ? '?' + query.join('&') : ''
  }

  return ''
}

/**
 * Thay thế 1 đoạn text cuối cùng bằng ký tự nào đó
 * @param {string} str
 * @param {number} num
 * @returns
 */
export const truncateString = (str, num, symbol = '...') => {
  if (str.length > num) {
    return str.slice(0, num) + symbol
  } else {
    return str
  }
}

/**
 * Loại bỏ tát cả các thể HTML
 * @param {string} html
 * @returns
 */
export function stripHtml(html) {
  let tmp = document.createElement('DIV')
  tmp.innerHTML = html
  return tmp.textContent || tmp.innerText || ''
}

/**
 * Sắp xếp lại mảng thành mảng dạng cây nhị phân
 * Demos
 * [{id: 1, parent_id: null}, {id: 2, parent_id: 1}, {id: 3, parent_id: 2}]
 * => [{id: 1, parent_id: null, children: [{id: 2, parent_id: 1, children: [{id: 3, parent_id: 2}]}]}]
 *
 * @param {Array} list
 * @param {String} fieldId
 * @param {String} fieldParent
 * @returns {Array}
 */
export function listToTree(list = [], fieldId = 'id', fieldParent = 'parent_id') {
  var map = {},
    node,
    roots = [],
    i

  for (i = 0; i < list.length; i += 1) {
    map[list[i][fieldId]] = i
    list[i].children = []
  }

  for (i = 0; i < list.length; i += 1) {
    node = list[i]
    if (node[fieldParent]) {
      if (map[node[fieldParent]] >= 0) {
        list[map[node[fieldParent]]].children.push(node)
      }
    } else {
      roots.push(node)
    }
  }

  return roots
}

/**
 * Sắp xếp lại mảng theo vị trí cha trước con sau
 * @param {Array} flatData
 * @returns {Array}
 */
export function flatSortByParent(flatData = []) {
  const resursive = (data, parent_id = null, depth = 0, resultList = []) => {
    data.forEach((item) => {
      item.depth = depth

      if (item.children.length > 0) {
        item.hasChildren = true
        resultList.push(item)

        resursive(item.children, item.id, item.depth + 1, resultList)
      } else {
        item.hasChildren = false
        resultList.push(item)
      }
    })

    return resultList
  }

  return resursive(listToTree(flatData))
}

export const mapStatusProduct = (status) => {
  if (status === 1) {
    return <ListItemText primary={'Hoạt động'} sx={{ color: 'green' }} />
  } else if (status === 2) {
    return <ListItemText primary={'Ngừng hoạt động'} sx={{ color: 'red' }} />
  } else if (status === 0) {
    return <ListItemText primary={'Bản nháp'} sx={{ color: 'orange' }} />
  } else {
    return <ListItemText primary={''} />
  }
}

export const mapStatusPost = (status) => {
  if (status === 1) {
    return <ListItemText primary={'Hoạt động'} sx={{ color: 'green', textAlign: 'center' }} />
  } else if (status === 0) {
    return <ListItemText primary={'Ngừng hoạt động'} sx={{ color: 'red', textAlign: 'center' }} />
  } else {
    return <ListItemText primary={''} />
  }
}

export function checkDecimalAndRound(number) {
  if (Number.isFinite(number)) {
    return Math.round(number * 100) / 100
  }
  return number
}

export const parseParams = (querystring) => {
  const params = new URLSearchParams(querystring)
  const obj = {}
  for (const key of params.keys()) {
    if (params.getAll(key).length > 1) {
      if (params.get(key) !== 'undefined') obj[key] = params.getAll(key)
    } else {
      if (params.get(key) !== 'undefined') obj[key] = params.get(key)
    }
  }

  return obj
}

export const removeUndefinedAttribute = (obj) => {
  console.log('obj', obj)
  const params = {}
  Object.keys(obj).forEach((key) => {
    if (obj[key] || obj[key] === 0) {
      params[key] = obj[key]
    }
    return {}
  })
  return params
}

/**
 * Delay
 * @param {*} delayInms 
 * @returns 
 */
export const delay = (delayInms) => {
  return new Promise(resolve => setTimeout(resolve, delayInms));
}

/**
 * Check current enviroment
 * @returns boolean
 */
export const checkLocalEnv = () => {
  return !!(window.location.hostname.indexOf('127.0.0.1') > -1 || window.location.hostname.indexOf('localhost') > -1)
}

/**
 * Download file from link
 * @param {string} url 
 */
export const downLoadLink = (url) => {
  let a = document.createElement("a");
  a.href = url;
  a.download = url;
  a.click();
}

export const getErrorMessageContent = (error) => {
  const [error_code, ...rest] = error.split(':');
  const message = rest.join(':');
  if (error_code && message) {
    return `${ERROR_MESSAGE[error_code.trim()]}: ${message.trim()}`;
  } else {
    return ERROR_MESSAGE[error.trim()];
  }
};
