// ==============================|| THEME CONFIG  ||============================== //

const config = {
  defaultPath: '/',
  fontFamily: `"-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif"`,
  i18n: 'en',
  miniDrawer: false,
  container: true,
  mode: 'light',
  presetColor: 'default',
  themeDirection: 'ltr',
}

export const SHARE_COOKIE_DOMAIN = process.env.REACT_APP_SHARE_COOKIE_DOMAIN || '127.0.0.1'

export default config
export const drawerBgColor = 'rgb(35, 48, 68)'
export const mainBgColor = '#f0f0f1'
export const drawerWidth = 220
export const headerHeight = 65

export const footerToolbarHeight = 65

export const twitterColor = '#1DA1F2'
export const facebookColor = '#3b5998'
export const linkedInColor = '#0e76a8'

export const domainImage = process.env.REACT_APP_UPLOAD_PATH

// Config env
export const appTitle = 'QUẢN LÝ KHO'
export const pricingApiEndpoint = process.env.REACT_APP_API_PRICING_URL || ''
export const domainWebApp = process.env.REACT_APP_DOMAIN_WEB_APP || 'https://web-dev.forlife.one'
export const domainForlifeHome = process.env.REACT_APP_FORLIFE_HOME || 'http://localhost:3001'