import { lazy } from 'react'

// project import
import Loadable from 'components/Loadable'

const InventoryLising = Loadable(lazy(() => import('pages/Inventory/Listing')))

const InventoryRoutes = [
  {
    path: 'inventories',
    element: <InventoryLising />,
  }
]

export default InventoryRoutes
