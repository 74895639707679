import { lazy } from 'react'

// project import
import Loadable from 'components/Loadable'

const UserProfile = Loadable(lazy(() => import('pages/Profile/UserProfile')))

const ProfileRoute = [
  {
    path: 'profile',
    element: <UserProfile />,
  }
]

export default ProfileRoute
