import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

// material-ui
import { Box, Collapse, List } from '@mui/material'

// project import
import NavItem from './NavItem'
import { useState } from 'react'
import CollapseHeader from './CollapseHeader'
import { MenuItem } from 'types/common'
import { RootState } from 'redux/store'

// ==============================|| NAVIGATION - LIST GROUP ||============================== //

interface Props {
  key: string,
  item: MenuItem
}

const NavGroup = ({ item } : Props) => {
  const menu = useSelector((state: RootState) => state.menu)
  const { drawerOpen } = menu
  const [open, setOpen] = useState(false)

  const navCollapse = item.children?.map((menuItem) => {
    return <NavItem key={menuItem.id} item={menuItem}/>
  })

  return (
    <List
      subheader={
        item.title &&
        drawerOpen && (
          <CollapseHeader key={item.id} item={item} open={open} onToggle={(val : boolean) => setOpen(val)} />
        )
      }
      sx={{ py: 0, zIndex: 0 }}
    >
      <Collapse
        component="li"
        in={open}
        timeout="auto"
        unmountOnExit
        style={{ backgroundColor: 'rgba(255,255,255, 0.08)' }}
      >
        <Box>{navCollapse}</Box>
      </Collapse>
    </List>
  )
}

NavGroup.propTypes = {
  item: PropTypes.object,
}

export default NavGroup
