import _ from 'lodash'
import jsCookies from 'js-cookie'
import { SHARE_COOKIE_DOMAIN } from 'config'

export default class Cookies {
  static get(key) {}

  static set(key, value) {}

  static remove(key) {
    return jsCookies.remove(key)
  }

  static setToken = (token, expiresIn) => {
    return jsCookies.set('token', token, { expires: parseInt(expiresIn), domain: SHARE_COOKIE_DOMAIN })
  }

  static getToken = async () => {
    const data = await jsCookies.get('token')

    return data || null
  }
}