import { configureStore } from '@reduxjs/toolkit'
import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import createSagaMidleware from '@redux-saga/core'
import sagas from './sagas'
import { combineReducers, Middleware } from 'redux'
import { authReducer } from './slices/authSlice'
import { menuReducer } from './slices/menuSlice'
import { layoutReducer } from './slices/layoutSlice'
import { userReducer } from './slices/userSlice'
import logger from 'redux-logger'
import { locationReducer } from './slices/locationSlice'

const isDevEnv = process.env.NODE_ENV !== 'production'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'location', 'menu', 'user'],
}

const reducers = combineReducers({
  auth: authReducer,
  layout: layoutReducer,
  menu: menuReducer,
  user: userReducer,
  location: locationReducer,
})
const persistedReducer = persistReducer(persistConfig, reducers)
const sagaMidleware = createSagaMidleware()

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    const middlewares: Array<Middleware> = [
      ...getDefaultMiddleware({
        thunk: false,
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
      sagaMidleware,
    ]
    if (isDevEnv) {
      middlewares.push(logger)
    }
    return middlewares
  },
  devTools: isDevEnv,
})
sagaMidleware.run(sagas)

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
