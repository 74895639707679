import { Alert, Box, CssBaseline, Snackbar, ThemeProvider } from '@mui/material'

import React, { useState } from 'react'
import { Provider, shallowEqual, useSelector } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { BASE_THEME } from './constants/theme'
import { initTranslations } from './languages'
import { persistor, RootState, store } from './redux/store'
import Routes from 'routes';

import { Message } from './types/common'
import useAppInit from 'hooks/useAppInit'
import { QueryClientProvider, QueryClient } from '@tanstack/react-query'

initTranslations()

function AppRoutes() {
  const { messages } = useSelector((state: RootState) => state.layout, shallowEqual)
  const [isLoading, setIsLoading] = useState(true)

  useAppInit(setIsLoading)

  let messageArr: Array<any> = Object.values(messages)

  const renderMessages = () => {
    const sortedMessages: Message[] = messageArr.sort((a: Message, b: Message): number =>
      a.createdAt > b.createdAt ? 1 : -1
    )

    const messagesElm = sortedMessages.map((msg, index) => (
      <Alert variant="filled" severity={msg.type} key={`notification-message-${index}`} sx={{ width: '100%', mb: 1 }}>
        {msg.content}
      </Alert>
    ))

    return messagesElm
  }

  return !isLoading ? (
    <>
      <Routes />
      <Snackbar open anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 1 }}>{renderMessages()}</Box>
        </Box>
      </Snackbar>
    </>
  ) : null
}

const App: React.FC = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  })

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <BrowserRouter>
          <ThemeProvider theme={BASE_THEME}>
            <QueryClientProvider client={queryClient}>
              <CssBaseline />
              <AppRoutes />
            </QueryClientProvider>
          </ThemeProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  )
}

export default App
