import { call, put, select, takeLatest } from 'redux-saga/effects'
import { getSelectedLocation, locationActions } from 'redux/slices/locationSlice'
import * as userService from '../../services/user.service'
import { userActions } from '../slices/userSlice'
import { Stores } from 'types/store'

const checkIsValidSelected = (selected: string | unknown, locations: Stores) => {
  const location = (locations || []).find(item => item.id === selected);
  return !location && selected;
};

/**
 * Get user info by token
 */
function* getUserToken({ payload }: any): Generator {

  try {
    const res: any = yield call(userService.getUserToken)
    const { locations } = res
    
    yield put(userActions.getUserTokenSuccess(res))
    
    yield put(locationActions.getUserLocationSuccess(res))
    
    // Choose the first location to default
    let selectedLocation = yield select(getSelectedLocation)
    
    if (locations.length > 0 && checkIsValidSelected(selectedLocation, locations)) {
      yield put(locationActions.userSelectedLocation(locations[0]?.id));
    }

    if (payload && payload.onSuccess) {
      payload.onSuccess(res)
    }
  } catch (error) {
    yield put(userActions.getUserTokenError(error))
    if (payload && payload.onError) {
      payload.onError(error)
    }
  }
}

export default function* userSaga() {
  yield takeLatest(userActions.getUserToken, getUserToken)
}
