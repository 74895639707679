export const isImage = (filePath) => {
  if (!filePath) return false

  const extensions = new Set([
    '3dv',
    'ai',
    'amf',
    'art',
    'ase',
    'awg',
    'blp',
    'bmp',
    'bw',
    'cd5',
    'cdr',
    'cgm',
    'cit',
    'cmx',
    'cpt',
    'cr2',
    'cur',
    'cut',
    'dds',
    'dib',
    'djvu',
    'dxf',
    'e2d',
    'ecw',
    'egt',
    'emf',
    'eps',
    'exif',
    'fs',
    'gbr',
    'gif',
    'gpl',
    'grf',
    'hdp',
    'heic',
    'heif',
    'icns',
    'ico',
    'iff',
    'int',
    'inta',
    'jfif',
    'jng',
    'jp2',
    'jpeg',
    'jpg',
    'jps',
    'jxr',
    'lbm',
    'liff',
    'max',
    'miff',
    'mng',
    'msp',
    'nef',
    'nitf',
    'nrrd',
    'odg',
    'ota',
    'pam',
    'pbm',
    'pc1',
    'pc2',
    'pc3',
    'pcf',
    'pct',
    'pcx',
    'pdd',
    'pdn',
    'pgf',
    'pgm',
    'PI1',
    'PI2',
    'PI3',
    'pict',
    'png',
    'pnm',
    'pns',
    'ppm',
    'psb',
    'psd',
    'psp',
    'px',
    'pxm',
    'pxr',
    'qfx',
    'ras',
    'raw',
    'rgb',
    'rgba',
    'rle',
    'sct',
    'sgi',
    'sid',
    'stl',
    'sun',
    'svg',
    'sxd',
    'tga',
    'tif',
    'tiff',
    'v2d',
    'vnd',
    'vrml',
    'vtf',
    'wdp',
    'webp',
    'wmf',
    'x3d',
    'xar',
    'xbm',
    'xcf',
    'xpm',
  ])

  const fileExt = filePath.split('.').pop()

  return extensions.has(fileExt.toLowerCase())
}

export const isFile = (input) => {
  if ('File' in window && input instanceof File) return true
  else return false
}

/**
 * Validate file type is exel file ot not
 * @param {*} filePath 
 * @returns boolean
 */
export const isFileExel = (filePath) => {
  if (!filePath) return false

  const extensions = new Set([
    'xlsx',
    'xls'
  ])

  const fileExt = filePath.split('.').pop()

  return extensions.has(fileExt.toLowerCase())
}

/**
 * Check if the url is valid
 * @param {string} urlString
 * @returns
 */
export const isValidUrl = (urlString) => {
  let url
  try {
    url = new URL(urlString)
  } catch (e) {
    return false
  }
  return url.protocol === 'http:' || url.protocol === 'https:'
}

export const isValidUrlImage = (urlString) => {
  return isValidUrl(urlString) ? urlString : process.env.REACT_APP_UPLOAD_PATH + urlString
}

export const checkImageProduct = (data) => {
  let image = {}
  if (data?.images?.length > 0) {
    image = data?.images[0]
  } else {
    if (data?.variants?.length > 0) {
      image = data?.variants[0]?.images[0]
    }
  }
  return image
}

