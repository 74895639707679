// project import
import inventory from './inventory'
import location from './location'
import order from './order'
import store from './store'
import user from './user'

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [order, inventory, location, store],
}

export default menuItems
