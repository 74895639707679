import { createSlice } from '@reduxjs/toolkit'
import { RootState } from 'redux/store'

const initialState = {
  locations: [],
  selectedLocation: '',
}

const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    getUserc: (state) => {
      return {
        ...state,
      }
    },
    getUserLocationSuccess: (state, action) => {
      return {
        ...state,
        locations: action.payload.locations,
      }
    },
    getUserLocationError: (state, action) => {
      return {
        ...state,
      }
    },
    userSelectedLocation: (state, action) => {
      window.location.reload()
      
      return {
        ...state,
        selectedLocation: action.payload,
      }
    },
    clearLocation: (state) => {
      return {
        locations: [],
        selectedLocation: '',
      }
    },
  },
})
export const { actions: locationActions, reducer: locationReducer } = locationSlice

export const getSelectedLocation = (state: RootState) => state.location.selectedLocation
