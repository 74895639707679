import PropTypes from 'prop-types'
import { useRef, useState } from 'react'

// material-ui
import { useTheme } from '@mui/material/styles'
import {
  Avatar,
  Box,
  Button,
  CardContent,
  ClickAwayListener,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Stack,
  Typography,
} from '@mui/material'

// project import
import MainCard from 'components/MainCard'
import Transitions from 'components/@extended/Transitions'

// assets
import avatar1 from 'assets/images/users/avatar-1.png'
import { EditOutlined, LogoutOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { authActions } from 'redux/slices/authSlice'
import { userSlector } from 'redux/slices/userSlice'
import UiAvatar from 'components/ui/UiAvatar'
import UserRoleLevel from 'constants/user-role-level'
import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { checkLocalEnv, createMessage } from 'utils/common'
import { MESSAGE_TYPES } from 'constants/common'
import _ from 'lodash'

// tab panel wrapper
function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`profile-tabpanel-${index}`}
      aria-labelledby={`profile-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const user = useSelector(userSlector)
  const navigate = useNavigate()
  const role = user?.user?.role
  const handleLogout = () => {
    // logout
    dispatch(authActions.logout())
  }

  const anchorRef = useRef(null)
  const [open, setOpen] = useState(false)
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  useEffect(() => {
    if (_.isArray(user.stores) && user.stores.length === 0) {
      dispatch(createMessage({ type: MESSAGE_TYPES.ERROR, content: 'Vui lòng chọn vị trí' }))
    }
  }, [])

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <Button
        size="small"
        sx={{
          p: 0.25,
          borderRadius: 1,
          '&:hover': { bgcolor: 'secondary.lighter' },
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
          <UiAvatar
            name={user?.user?.name || user?.user?.username}
            src={user?.user?.avatar}
            style={{ width: 38, height: 38 }}
          />
          <Stack
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={0.5}
            sx={(theme) => ({
              [theme.breakpoints.down('sm')]: {
                display: 'none',
              },
            })}
          >
            <Typography sx={{ fontWeight: 'bold', lineHeight: '1em' }}>
              {user?.user?.name || user?.user?.username}
            </Typography>
            {role && <Typography variant="body2" sx={{ lineHeight: '1em' }}>
              {UserRoleLevel[user.user.role.level]}
            </Typography>}
          </Stack>
        </Stack>
      </Button>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 9],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            {open && (
              <Paper
                sx={{
                  // boxShadow: theme.customShadows.z1,
                  width: 210,
                  minWidth: 240,
                  maxWidth: 290,
                  [theme.breakpoints.down('md')]: {
                    maxWidth: 250,
                  },
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MainCard elevation={0} border={false} content={false}>
                    {open && (
                      <>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                          <List
                            component="nav"
                            sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32, color: theme.palette.grey[500] } }}
                          >
                            <ListItemButton
                              onClick={(event) => {
                                navigate('/profile')
                                setOpen(false)
                              }}
                            >
                              <ListItemIcon>
                                <EditOutlined />
                              </ListItemIcon>
                              <ListItemText primary="Hồ sơ cá nhân" />
                            </ListItemButton>
                            {checkLocalEnv() ? (
                              <ListItemButton onClick={handleLogout}>
                                <ListItemIcon>
                                  <LogoutOutlined />
                                </ListItemIcon>
                                <ListItemText primary="Đăng xuất" />
                              </ListItemButton>
                            ) : null}
                          </List>
                        </Box>
                      </>
                    )}
                  </MainCard>
                </ClickAwayListener>
              </Paper>
            )}
          </Transitions>
        )}
      </Popper>
    </Box>
  )
}

export default Profile
