// material-ui
import { Box, IconButton, Link, useMediaQuery, useTheme } from '@mui/material'
import { GithubOutlined } from '@ant-design/icons'

// project import
import Search from './Search'
import Profile from './Profile'
import Notification from './Notification'
import MobileSection from './MobileSection'
import SelectStore from './SelectStore'
import ServiceName from './ServiceName'

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(() => theme.breakpoints.down('md'))

  return (
    <>
      {!matchesXs && <ServiceName />}
      <Box sx={{ width: '100%' }}></Box>
      {/* <Notification /> */}
      <SelectStore />
      <Profile />
      {/* {!matchesXs && <Profile />} */}
      {/* {matchesXs && <MobileSection />} */}
    </>
  )
}

export default HeaderContent
