import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { userActions } from 'redux/slices/userSlice'
import LocalStorage from 'utils/localStorage'
import Cookies from 'utils/cookies'
import { domainForlifeHome } from 'config'
import { checkLocalEnv } from 'utils/common'

function useAppInit(setIsLoading) {
  const dispatch = useDispatch()

  const urlParams = new URLSearchParams(window.location.search)
  const token_url = urlParams.get('token')

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const roles = await LocalStorage.getDataList('roles')

        // if (!roles) {
        //   const res = await roleService.getRoles()
        //   await LocalStorage.setDataList('roles', {
        //     data: res,
        //     expiredTime: moment().add(30, 'd').format(),
        //   })
        // }
      } catch (error) {}
    }

    const setToken = async (token) => {
      const storageToken = await Cookies.getToken()
      
      if (token) {
        await Cookies.setToken(token)
        init(token)
      } else {
        if (storageToken) {
          init(storageToken)
        } else {
          if (checkLocalEnv()) {
            if (window.location.href.indexOf('/login') === -1) window.location.href = '/login'
          } else {
            if (window.location.href.indexOf('/login') === -1) window.location.href = domainForlifeHome
          }
        }
      }
    }

    setToken()

    const init = async (token) => {
      if (token) {
        dispatch(
          userActions.getUserToken({
            onSuccess: () => {
              setIsLoading(false)
              fetchData()
            },
            onError: () => {
              setIsLoading(false)
            },
          })
        )
      } else {
        setIsLoading(false)
      }
    }
  }, [])
}

export default useAppInit
