import axios from 'axios'
import { lowerCase } from 'lodash'
import { isValidUrl } from 'utils/validate'
import { authActions } from '../redux/slices/authSlice'
import { store } from '../redux/store'
import LocalStorage from '../utils/localStorage'
import { createMessage } from 'utils/common'
import { MESSAGE_TYPES } from 'constants/common'
import Cookies from 'utils/cookies'

const TIMEOUT = 5000

let refreshingToken = false

type CommonHeaderProperties = {
  Accept?: string,
  'content-type'?: string
  'Accept-Language'?: string
  Authorization?: string,
  responseType?: string
}

export const funcFetch = async (
  endPoint: string,
  data = null,
  method = 'get',
  headers: CommonHeaderProperties = {}
) => {
  headers['Accept-Language'] = 'vn'
  const token = await Cookies.getToken()

  if (token) {
    headers = {
      ...headers,
      Authorization: 'Bearer ' + token,
    }
  }

  return axios({
    url: isValidUrl(endPoint) ? endPoint : process.env.REACT_APP_API_URL + endPoint,
    method,
    headers,
    data,
    params: lowerCase(method) === 'get' ? data : null,
    timeout: TIMEOUT,
  })
    .then((res) => {
      const { status, data } = res

      if (status < 300) {
        if (data) return data?.data || data
      } else {
        throw new Error(data.message)
      }

      return Promise.reject(res)
    })
    .catch((error) => {
      const {
        response: { status, data },
      } = error

      if (status === 401 || status === 403) {
        // store.dispatch(authActions.logout())
        console.log(createMessage({ type: MESSAGE_TYPES.ERROR, content: data.message }))
        // store.dispatch(createMessage({ type: MESSAGE_TYPES.ERROR, content: data.message }))
      } else {
        
        console.log(createMessage({ type: MESSAGE_TYPES.ERROR, content: data.message }))
        // store.dispatch(createMessage({ type: MESSAGE_TYPES.ERROR, content: data.message }))
      }

      throw new Error(data?.message || '')
    })
}

const fetchApi = async (endPoint = '', data: any = null, method = 'get', headers: CommonHeaderProperties = {}) => {
  return funcFetch(endPoint, data, method, headers)
}

export default fetchApi
