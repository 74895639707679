import { lazy } from 'react'

// project import
import Loadable from 'components/Loadable'

const StoreListing = Loadable(lazy(() => import('pages/Store/List/StoreListing')))

const StoreRoutes = [
  {
    path: 'stores',
    element: <StoreListing />,
  }
]

export default StoreRoutes
