import { Box, ListItemText, MenuItem } from '@mui/material'
import Select from '@mui/material/Select'
import { Location } from 'pages/Store/types'
import { useSelector, useDispatch } from 'react-redux'
import { locationActions } from 'redux/slices/locationSlice'
import { RootState } from 'redux/store'

function SelectStore() {
  const dispatch = useDispatch()
  const userLocations: Location[] = useSelector((state: RootState) => state.location?.locations)
  const selectedLocation: string = useSelector((state: RootState) => state.location?.selectedLocation)

  return (
    <Box
      sx={{
        display: 'flex',
        width: '50%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'end',
        columnGap: '16px',
      }}
    >
      <Select
        value={selectedLocation}
        size="small"
        displayEmpty
        defaultValue=""
        multiple={false}
        onChange={(e) => dispatch(locationActions.userSelectedLocation(e.target.value))}
      >
        {userLocations.length > 0 ?
          userLocations.map((item, index) => {
            return (
              <MenuItem key={index.toString()} value={item.id}>
                <ListItemText primary={item.name} />
              </MenuItem>
            )
          })
          : null}
      </Select>
    </Box>
  )
}

export default SelectStore
