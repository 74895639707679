import {
  Alert,
  Box,
  Collapse,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled as MUIStyled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

//Services
import { authActions } from "../../redux/slices/authSlice";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { Visibility, VisibilityOff } from "@mui/icons-material";

type Props = {
  className?: string;
}

const Login = MUIStyled(({ className }: Props): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isInvalidCredentials, setIsInvalidCredentials] = useState(false);
  const [showPassword, setShowPassword] = useState<boolean>(false)

  const handleSubmit = (values: { username: string, password: string }, { setSubmitting }: { setSubmitting: Function }) => {
    setSubmitting(true);

    try {
      dispatch(
        authActions.login({
          ...values,
          meta: {
            onSuccess: () => {
              setSubmitting(false);
              window.location.href = "/";
            },
            onError: () => {
              setSubmitting(false);
              setIsInvalidCredentials(true);
            },
          },
        })
      );
    } catch (error) {
      setSubmitting(false);
    }
  };

  if (useAuth()) {
    return <Navigate to="/orders" />;
  }

  return (
    <div className={["auth-page", className].join()}>
      <BoxWrapper>
        <Grid
          container
          direction="column"
          justifyContent="flex-end"
          sx={{
            minHeight: "100vh",
          }}
        >
          <Grid item xs={12}>
            <Grid
              item
              container
              justifyContent="center"
              alignItems="center"
              sx={{ minHeight: { xs: "100vh", md: "100vh" } }}
            >
              <Grid item>
                <PaperWapper elevation={0}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} textAlign="center">
                      <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                        {t("auth.login.title")}
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Formik
                        initialValues={{
                          username: "",
                          password: "",
                        }}
                        validationSchema={Yup.object().shape({
                          username: Yup.string().required(
                            t("validate.required", {
                              field: t("auth.login.username"),
                            })
                          ),
                          password: Yup.string().required(
                            t("validate.required", {
                              field: t("auth.login.password"),
                            })
                          ),
                        })}
                        onSubmit={(
                          values,
                          { setErrors, setStatus, setSubmitting }
                        ) =>
                          handleSubmit(values, {
                            setSubmitting,
                          })
                        }
                      >
                        {({
                          values,
                          errors,
                          isSubmitting,
                          touched,
                          handleBlur,
                          handleChange,
                          handleSubmit,
                        }) => (
                          <form noValidate onSubmit={handleSubmit}>
                            {isInvalidCredentials && (
                              <Collapse in={isInvalidCredentials}>
                                <Alert
                                  severity="error"
                                  action={
                                    <IconButton
                                      aria-label="close"
                                      color="inherit"
                                      size="small"
                                      onClick={() => {
                                        setIsInvalidCredentials(false);
                                      }}
                                    >
                                      <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                  }
                                  sx={{ mb: 2 }}
                                >
                                  Tên đăng nhập hoặc mật khẩu không đúng
                                </Alert>
                              </Collapse>
                            )}
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <TextField
                                  label={t("auth.login.username")}
                                  variant="outlined"
                                  fullWidth
                                  name="username"
                                  value={values.username}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  error={Boolean(
                                    touched.username && errors.username
                                  )}
                                />
                                {errors.username && touched.username && (
                                  <FormHelperText error>
                                    {errors.username}
                                  </FormHelperText>
                                )}
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  type={showPassword ? 'text' : 'password'}
                                  label={t("auth.login.password")}
                                  variant="outlined"
                                  fullWidth
                                  name="password"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  error={Boolean(
                                    touched.password && errors.password
                                  )}
                                  InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword(!showPassword)}
                                        edge="end"
                                      >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                      </IconButton>
                                    </InputAdornment>
                                  }}
                                />
                                {errors.password && touched.password && (
                                  <FormHelperText error>
                                    {errors.password}
                                  </FormHelperText>
                                )}
                              </Grid>
                              <Grid item xs={12} justifyContent="flex-center">
                                <LoadingButton
                                  type="submit"
                                  variant="contained"
                                  fullWidth
                                  size="large"
                                  loading={isSubmitting}
                                >
                                  {t(
                                    `auth.login.${isSubmitting ? "loading" : "submit"
                                    }`
                                  )}
                                </LoadingButton>
                              </Grid>
                            </Grid>
                          </form>
                        )}
                      </Formik>
                    </Grid>
                  </Grid>
                </PaperWapper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </BoxWrapper>
    </div>
  );
})(({ theme }) => ({}));

const BoxWrapper = MUIStyled(Box)`
  background-color: rgb(247, 249, 252);
`;

const PaperWapper = MUIStyled(Paper)`
  max-width: 470px;
  width: calc(100% - 20px);
  margin: auto;
  padding: 30px 40px;
`;

export default Login