import ListIcon from '@mui/icons-material/List'

const inventory = {
  id: 'inventory',
  title: 'Danh sách tồn',
  type: 'item',
  url: "inventories",
  icon: ListIcon,
  breadcrumbs: false,
  role: ['*'],
}

export default inventory
