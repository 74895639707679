import EditLocationOutlinedIcon from '@mui/icons-material/EditLocationOutlined';

const location = {
  id: 'location',
  title: 'Vị trí',
  type: 'item',
  url: '/locations',
  icon: EditLocationOutlinedIcon,
  breadcrumbs: false,
  role: ['*'],
}

export default location
