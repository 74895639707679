import { Navigate, useRoutes } from 'react-router-dom';

import AuthRoutes from './AuthRoutes';
import useAuth from 'hooks/useAuth';
import MainLayout from 'layout/MainLayout';
import OrderRoutes from './Order';
import InventoryRoutes from './Inventory';
import LocationRoutes from './Location';
import UserRoutes from './User';
import ProfileRoute from './Profile';
import StoreRoutes from './Store';
import PageNotFound from 'pages/404';
import { checkLocalEnv } from 'utils/common';
import Login from 'pages/Auth/Login';

export const GoToForlifeHome = ({}) => {
    window.location.href = `${process.env.REACT_APP_FORLIFE_HOME}/login`
  
    return <></>
  }
  

export default function ThemeRoutes() {
    const isAuthenticated = useAuth()
    let privateRoutes = [OrderRoutes, InventoryRoutes, LocationRoutes, ProfileRoute, StoreRoutes]
    
    let routes = []

    if(checkLocalEnv()){
        routes = [
            {
                path: '/',
                element: isAuthenticated ? <MainLayout /> : <Navigate to="/login" />,
                children: privateRoutes.flat()
            },
            {
                path: '/login',
                element: <Login />
            },
            {
                path: "*",
                element: <PageNotFound />
            }
        ]
    }else{
        routes = [
            {
                path: '/',
                element: isAuthenticated ? <MainLayout /> : <GoToForlifeHome />,
                children: privateRoutes.flat()
            },
            {
                path: "*",
                element: <PageNotFound />
            }
        ]
    }

    return useRoutes(routes)
}
