import { lazy } from 'react'

// project import
import Loadable from 'components/Loadable'

const LocationLising = Loadable(lazy(() => import('pages/Location/List/LocationListing')))

const LocationRoutes = [
  {
    path: 'locations',
    element: <LocationLising />,
  }
]

export default LocationRoutes
