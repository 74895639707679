import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';

const order = {
  id: 'order',
  title: 'Đơn hàng',
  type: 'item',
  icon: FolderOutlinedIcon,
  url: '/orders',
  breadcrumbs: false,
  role: ['*'],
}

export default order
